import Component from '../../libs/component';
import { register } from '../../libs/register';

class LogosGrid extends Component {
    constructor(container) {
        super('widget-logos-grid');

        this.grid = container;
        this.items = container.getElementsByClassName(this._el('item'));
        this.loadButton = container.querySelector('.elem-cta-load-more');

        this._init();
    }

    _init() {

        if (this.loadButton) {
            
            this.loadButton.addEventListener('click', (ev) => {
                ev.preventDefault();
                this._fetchImages();
            });

            this.endpoint = this.loadButton.dataset.url || '';
            delete this.loadButton.dataset.url;
        }
    }

    _fetchImages() {

        let request = new XMLHttpRequest();
        let data;
        let dataObj;
        let resPath = this.endpoint ? this.endpoint : '/assets/files/logo-grid-test.json';
        let gridPointer = this.items.length || 0;

        resPath += `?gridPointer=${gridPointer}`;

        request.open('GET', resPath, true );
        
        request.onload = () => {

            if (request.status === 200) {
                
                data = request.responseText;
                dataObj = JSON.parse(data);

                if (!dataObj.itemsLeft && this.loadButton) {
                    this.loadButton.style.display = 'none';
                }

                dataObj.logoBoxesToLoad.forEach((logo) => {

                    let logoItem = document.createElement('div');
                        logoItem.classList.add(this._el('item'));
                    
                    let title = document.createElement('div');
                        title.appendChild(document.createTextNode( logo.description !== 'null' ? logo.description : '' ));
                        title.classList.add(this._el('item-text'));

                    let link = document.createElement('a');
                        link.classList.add(this._el('item-link'));
                        if(logo.logoLink.href) {
                            link.href = logo.logoLink.href;
                        }
                        if(logo.logoLink.modelHashMap.target) {
                            link.target = logo.logoLink.modelHashMap.target;
                        }

                    let imgContainer = document.createElement('div');
                        imgContainer.classList.add(this._el('item-image'));

                    let img = document.createElement('img');
                        img.src = logo.logoReference;
                        img.alt = logo.alt !== 'null' ? logo.alt : 'Logo';

                        imgContainer.appendChild(img);
                       
                        link.appendChild(imgContainer);

                        logoItem.appendChild(link);
                        logoItem.appendChild(title);

                        this.grid
                            .querySelector(this._el('item-container',true))
                            .appendChild(logoItem);
                });
            }

            if (request.status === 204 && this.loadButton)
                this.loadButton.style.display = 'none';
        }

        request.onerror = () => {
            console.warn('Logos "Load more" network ERROR');
        };
        
        request.send();
    }
}

register.registerClass('.widget-logos-grid', LogosGrid);