import Component from '../../libs/component';
import { register } from '../../libs/register';


class MediaVideo extends Component {

    constructor(container) {
        super('media-video');

        this.container = container;
        this.iframe = this.container.querySelector('iframe');
        this.button = this.container.querySelector(this._el('play-button',true));
        this.placeholderImage = this.container.querySelector(this._el('placeholder-image', true));

        if (!this.placeholderImage) {
            this._loadVideo();
            this.container.classList.add('no-placeholder-image');
        } else {
            if (this.button) {
                this.button.classList.add('visible-button');
                this.button.addEventListener('click', () => {
                    this._loadVideo();
                    this.button.classList.remove('visible-button');
                });
            }
        }        
      
    }

    _loadVideo() {
        this.iframe.src = this.iframe.dataset.src;
    }
}

register.registerClass('.media-video', MediaVideo);