import Component from '../../libs/component';
import { register } from '../../libs/register';
import { request } from '../../libs/utils';




class CalendarEvents extends Component {

    constructor(container) {
        super('widget-calendar-events');

        this.container = container;
        this.calContainer = container.querySelector(this._el('grid',true));
        this.eventsContainer = container.querySelector(this._el('events-container',true));
        this.legendContainer = container.querySelector(this._el('legend',true));

        this.EVENTS_SKELETON_CARDS_NUM = 4;
        this.EVENT_TYPES_PER_DAY_ENDPOINT = this.calContainer.dataset.urlTypesperday || '';
        this.EVENTS_LIST_ENDPOINT = this.calContainer.dataset.urlEvents || '';

        this.localUrls = this.getLocalUrls();
        this.lang = document.documentElement.lang;
        this.abbrDay = true;
        this.abbrMonth = false;
        this.abbrYear = false;
        this.events = new Map();
        this.eventsCategories = {};
        this.legendCategories = new Map();
        this.localDate = new Date();
        this.day = this.localDate.getDate();
        this.month = this.localDate.getMonth();
        this.year = this.localDate.getFullYear();
        this.activeMonth = this.month;  // Actual drawn month
        this.activeYear = this.year;    // Actual year of the drawn month
        this.daysOfWeek = this.getWeekDays();
        this.monthsName = this.getMonths();
        // Adjusting time for UTC+1 Rome time zone reference frame
        this.timeZoneOffset = this.localDate.getTimezoneOffset() + 60;
        this.currentYearEndDate = `${this.year}/12/${this.getLastMonthDay(11)}`;
        //  Try to load all the next year's events starting from October
        this.futureYearEndDate = this.month >= 9 ? `${this.year+1}/12/${this.getLastMonthDay(11)}` : null;
        
        this.eventTemplate = this.eventsContainer.querySelector(this._el('event-item',true)) || null;
        if (this.eventTemplate) this.eventsContainer.querySelector(this._el('event-item',true)).remove();

        if (this.calContainer.dataset.categories) {
            const categoriesArr = JSON.parse(this.calContainer.dataset.categories);
            delete this.calContainer.dataset.categories;
            this.legendCategories = new Map(categoriesArr.map(obj => [obj.type, obj]));
        }

        // Built-in event handlers
        this._setNextMonth = this.setNextMonth.bind(this);
        this._setPreviousMonth = this.setPreviousMonth.bind(this);
        this._dayClicked = this.dayClicked.bind(this);
        this._disableIcsLink = this.disableIcsLink.bind(this);
        this._addCustomScrollBar = this.addCustomScrollBar.bind(this);
        this._removeCustomScrollBar = this.removeCustomScrollBar.bind(this);
        
        this.drawCalendar();
        this.drawLegend();
        this.addEventsCategories();
        this.fetchEventsList()  // Fetch all events starting from current month 'till end of year or the next year
            .then(() => this.drawEventsList()); // Show all stored events starting from current day

        if (window.deviceBreakpoints.bpDesktop.matches === true)
            this.addCustomScrollBar();

        this.container.classList.add('init');
    }

    getWeekDays() {

        const baseDate = new Date(Date.UTC(1970, 0, 5)); // just a Monday
        let weekDays = [];

        for (let i = 0; i < 7; i++) {
            weekDays.push(baseDate.toLocaleDateString(this.lang, { weekday: 'narrow' }));
            baseDate.setDate(baseDate.getDate() + 1);
        }

        return weekDays;
    }

    getMonths() {

        const baseDate = new Date(Date.UTC(1970, 0, 1));
        let months = [], month = '';

        for (let i = 0; i < 12; i++) {
            month = baseDate.toLocaleDateString(this.lang, { month: 'long' });
            months.push(`${month[0].toUpperCase()}${month.slice(1)}`);
            baseDate.setMonth(baseDate.getMonth() + 1);
        }

        return months;
    }

    getLocalUrls() {
        return this.calContainer.dataset.localUrls || false;
    }

    getLastMonthDay(month) {
        let newMonth = month? month+1 : this.activeMonth+1;
        if (newMonth > 11)
            return new Date(this.activeYear, 11, 31).getDate();
        else
            return new Date(this.activeYear, newMonth, 0).getDate();
    }

    getCategoryColors(categoryName) {
        const categoryData = this.legendCategories.get(categoryName);
        return { color: categoryData.color || '', altColor: categoryData.altColor || ''};
    }

    getFormattedStringDate(day,month,year) {
        let date = `${year}/${month+1}/${day}`;  // Fixing zero index format for month
        return encodeURIComponent(date);
    }

    getStringFromDate(date) {   // Returns a date string ready for servlet use
        if (date) {
            let dateStr = `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`;  // Fixing zero index format for month
            return encodeURIComponent(dateStr);
        }
        return false;
    }

    getCategoriesData(month,year) {

        if (!month) month = this.activeMonth;
        if (!year) year = this.activeYear;

        let yearData = this.eventsCategories[year];

        return yearData ? yearData[month] : null;
    }

    storeCategoriesData(data,month,year) {
        
        if (!month) month = this.activeMonth;
        if (!year) year = this.activeYear;
        
        console.log('+++ Storing Categories Data:',year,month+1,data);

        if (!this.eventsCategories[year])
            this.eventsCategories[year] = {};
        
        this.eventsCategories[year][month] = data;
    }

    getEventsData(year) {

        if (year) return this.events.get(year);

        let eventsData = [];

        for (const value of this.events.values()) {
            eventsData = eventsData.concat(value);
        }

        return eventsData;
    }

    storeEventsData(data) {

        let year,
            yearEvents = [];

        for (const [,event] of data.entries()) {

            if (year != event.date.year) {
                year = event.date.year;

                if (!this.events.has(year))
                    this.events.set(year, []);

                yearEvents = this.events.get(year);
            }

            yearEvents.push(event);
        }

        console.log('++++++ Storing All Events Data:', this.events);
    }

    eventsAvailable(year) {
        return this.events.has(year);
    }
    
    async drawNextMonth(addEvents){
        this.activeMonth = this.activeMonth + 1 > 11 ? 0 : this.activeMonth + 1;

        if (this.activeMonth === 0)
            this.activeYear++;

        await this.drawCalendar();
        this.addEventsCategories();

        if (addEvents) await this.drawEventsList();
    }

    async drawPreviousMonth(addEvents) {
        this.activeMonth = this.activeMonth - 1 > -1 ? this.activeMonth - 1 : 11;

        if (this.activeMonth === 11)
            this.activeYear--;

        await this.drawCalendar();
        this.addEventsCategories();

        if (addEvents) await this.drawEventsList();
    }

    setNextMonth() {
        let sel = this.clearSelection();
        this.drawNextMonth(sel);
    }
    
    setPreviousMonth() {
        let sel = this.clearSelection();
        this.drawPreviousMonth(sel);
    }

    setArrowBtnVisibility() {

        let prevArrowBtn = this.calContainer.querySelector(this._el('prev-month',true));
        let nextArrowBtn = this.calContainer.querySelector(this._el('next-month',true));
        prevArrowBtn.removeAttribute('disabled');

        if ((this.activeMonth === this.month) && (this.activeYear === this.year))
            this.enableArrowButton(prevArrowBtn,false);

        this.enableArrowButton(nextArrowBtn);

        if (this.activeMonth == 11) {
            // If next year's events are not available hide button next
            if (!this.eventsAvailable(this.activeYear+1))
                this.enableArrowButton(nextArrowBtn,false);
        }
    }

    enableArrowButton(btn,enable=true) {
        if (!btn) return;
        btn.disabled = enable;
        enable ? btn.removeAttribute('disabled') : btn.setAttribute('disabled','');
    }

    async addCustomScrollBar() {

        const eventsListContainer = this.eventsContainer.querySelector(this._el('events-list',true));
        const PerfectScrollbar = await import('perfect-scrollbar');
        this.scrollbar = new PerfectScrollbar.default(eventsListContainer, {
            suppressScrollX: true,
            wheelPropagation: false,
            swipeEasing: true
        });

        this.scrollbar.update();
    }

    removeCustomScrollBar() {

        if (this.scrollbar) {
            this.scrollbar.destroy();
            this.scrollbar = null;
        }
    }

    scrollToCalendar() {

        const containerBox = this.calContainer.getBoundingClientRect();
        const headerHeight = document.querySelector('header').getBoundingClientRect().height;
        const offsetTop = containerBox.top + (window.scrollY || window.pageYOffset) - headerHeight;
        const scrollOptions = {
            left: 0,
            top: offsetTop,
            behavior: 'smooth'
        }

        window.scrollTo(scrollOptions);
    }

    async dayClicked(ev) {
        
        let dayCell = ev.target;
        
        if (dayCell.classList.contains(this._el('day-cell'))) {

            let otherDay, selectedDay, selectedDate, selectedOtherDate;
            const active = dayCell.classList.contains('selected');

            this.showEventsMessage();

            if (!this.calContainer.classList.contains('multi-day-selected')) {

                if (this.calContainer.classList.contains('day-selected') && !active) {

                    let otherCell = this.calContainer.querySelector(`${this._el('day-cell',true)}.selected`);
                    otherDay = parseInt(otherCell.dataset.day) || null;
                } else {
                    this.clearSelection();
                }

                //console.log(selectedDay,otherDay);

            } else {
                this.clearSelection();
            }

            if (!active)
                selectedDay = parseInt(dayCell.dataset.day);

            this.setMultiSelection(selectedDay,otherDay);

            if (selectedDay)
                selectedDate = `${this.activeYear}/${this.activeMonth+1}/${selectedDay}`;

            if (otherDay)
                selectedOtherDate = `${this.activeYear}/${this.activeMonth+1}/${otherDay}`;
            
            this.drawEventsList(selectedDate,selectedOtherDate);

            if (window.deviceBreakpoints.bpDesktop.matches === false)
                this.scrollToCalendar();
        }
    }

    setMultiSelection(date1,date2) {

        if (date1) {
            
            let cell = this.calContainer.querySelector(`[data-day="${date1}"]`);
            cell.classList.add('selected');
            this.calContainer.classList.add('day-selected');

            if (date2) {

                const { low: startDate, high: endDate } = this.compareNumbers(date1,date2);
    
                for (let i = startDate; i <= endDate; i++) {
                    cell = this.calContainer.querySelector(`[data-day="${i}"]`);
                    cell.classList.add("multisel-day");
                    if (i === startDate) cell.classList.add("start-day");
                    if (i === endDate) cell.classList.add("end-day");
                }

                this.calContainer.classList.add('multi-day-selected');
                console.log('multiselect enabled');
            }
        } else {
            this.clearSelection();
        }
    }
    
    clearSelection() {

        let multiSel = this.calContainer.classList.contains('multi-day-selected');

        if (multiSel) {

            let multiDays = this.calContainer.querySelectorAll('.start-day, .multisel-day, .end-day');

            for (let i = multiDays.length; i--;)
                multiDays[i].classList.remove('start-day','multisel-day','end-day');

            this.calContainer.classList.remove('multi-day-selected');
        }

        let selectedDays = [...this.calContainer.getElementsByClassName('selected')];

        for (let i = selectedDays.length; i--;)
            selectedDays[i].classList.remove('selected');

        this.calContainer.classList.remove('day-selected');

        return multiSel || selectedDays.length > 0;
    }

    setEventsListTitle(show=true,date) {

        const titleContainer = this.eventsContainer.querySelector(this._el('events-title',true));
        
        titleContainer.classList.remove('hidden','future','past');

        if (!show)
            titleContainer.classList.add('hidden');

        if (date) {
            const newTime = date.getTime();
            const todayTime = new Date(this.year,this.month,this.day).getTime();

            if (newTime >= todayTime)
                titleContainer.classList.add('future');
            else
                titleContainer.classList.add('past');
        }
    }

    async fetchEventsCategories(month,year) {

        if (!month) month = this.activeMonth;
        if (!year) year = this.activeYear;

        let params = !this.localUrls ? `?month=${month+1}&year=${year}` : `-${year}-${month+1}.json`; // Servlet do not use zero index format for month

        console.log('Fetching Categories for Month:',month+1,'and Year:',year);

        return request({url: `${this.EVENT_TYPES_PER_DAY_ENDPOINT}${params}`})
            .then(resolve => {

                let catData = resolve.response ? JSON.parse(resolve.response) : [];

                if (!catData.length)
                    return [];

                this.storeCategoriesData(catData,month,year);
                
                return catData;
            })
            .catch((reject) => {
                console.warn('Events categories servlet error!',reject.response);
                return [];
            });
    }

    async addEventsCategories() {

        const categoriesList = this.getCategoriesData() || await this.fetchEventsCategories();
        //console.log(eventsList);

        if (categoriesList.length) {

            for (let [,data] of categoriesList.entries()) {

                if (parseInt(data.year) === this.activeYear && parseInt(data.month) === this.activeMonth) {

                    const dayCell = this.calContainer.querySelector(`[data-day="${parseInt(data.day)}"]`);
                    
                    if (dayCell) {
                        dayCell.classList.add(this._el('event-day'));
                        const dayCategoriesCell = dayCell.querySelector(this._el('day-categories',true));

                        if (this.legendCategories.size) {

                            for (let [category, categoryData] of this.legendCategories) {
                                for (let type of data.eventTypes) {

                                    if (type == category) {

                                        const categoryElem = document.createElement('li');
                                        const bgColor = `background-color: ${categoryData.color || ''}; --bg-alt-color: ${categoryData.altColor || categoryData.color || ''};`;

                                        categoryElem.textContent = categoryData.label || '';
                                        categoryElem.dataset.category = categoryData.type || '';
                                        categoryElem.setAttribute('style', bgColor);

                                        dayCategoriesCell.appendChild(categoryElem);
                                        //cssVars();  // Applying polyfill for IE css vars

                                        //console.log(data.day,categoryData)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        return this;
    }

    async fetchEventsList(date1,date2) {

        this.drawEventsSkeletonList();

        const dateA = date1 || `${this.activeYear}/${this.activeMonth+1}/1`,
              dateB = date2 || this.futureYearEndDate || this.currentYearEndDate,
              { low, high } = this.compareDates(dateA,dateB),
              dateFrom = this.getStringFromDate(low),
              dateTo = this.getStringFromDate(high),
              params = !this.localUrls ? `?dateFrom=${dateFrom}&dateTo=${dateTo}` : '';

        console.log('Fetching Events from:',decodeURIComponent(dateFrom),'to:',decodeURIComponent(dateTo));

        return request({url: `${this.EVENTS_LIST_ENDPOINT}${params}`})
            .then(resolve => resolve.response ? JSON.parse(resolve.response) : [])
            .then(eventsData => {

                if (!eventsData.length) {
                    console.warn('*** No events data returned!');
                    this.cleanEventsList();
                    return false;
                }

                this.storeEventsData(eventsData);
                
                return true;
            })
            .catch((reject) => {
                console.warn('*** Events List servlet ERROR!',reject.response);
                this.cleanEventsList();
            });
    }

    async drawEventsList(date1,date2) {

        const dateA = date1 || `${this.year}/${this.month+1}/${this.day}`,
              dateB = date2 || date1 || this.futureYearEndDate || this.currentYearEndDate,
              { low: selDateFrom, high: selDateTo } = this.compareDates(dateA,dateB),
              //eventsList = this.getEventsData() || await this.fetchEventsList() || [],
              eventsList = this.getEventsData() || [],  // Gets all year's events
              eventsListContainer = this.eventsContainer.querySelector('ul');
        let addedEvents = 0;

        if (!date1) {   // Setting current time for the start date
            selDateFrom.setHours(new Date().getHours());
            selDateFrom.setMinutes(new Date().getMinutes());
        }

        // Setting end date to midnight
        selDateTo.setHours(23);
        selDateTo.setMinutes(59);
        selDateTo.setSeconds(59);

        console.log(`*** Building Events List for date: ${selDateFrom} --> ${selDateTo}`);

        if (eventsList.length) {

            eventsListContainer.innerHTML = "";
            this.showEventsMessage();

            for (let [,event] of eventsList.entries()) {
                let startDateTime = this.convertObjToDate(event.date,event.startTime || {hourOfDay:0,minute:0}).getTime(),
                    endDateTime = this.convertObjToDate(event.endDate,event.endTime || {hourOfDay:23,minute:59}).getTime();

                //console.log('-----',new Date(startDateTime),new Date(endDateTime))

                if (this.timeZoneOffset) {
                    let offsetMs = this.timeZoneOffset * 60 * 1000;
                    startDateTime = Math.sign(offsetMs) > 0 ? startDateTime + offsetMs : startDateTime - offsetMs;
                    endDateTime = Math.sign(offsetMs) > 0 ? endDateTime + offsetMs : endDateTime - offsetMs;

                    //console.log('/////',new Date(startDateTime),new Date(endDateTime))
                }

                //console.log('-----',new Date(startDateTime),new Date(endDateTime))

                if (selDateFrom.getTime() <= endDateTime && selDateTo.getTime() >= startDateTime) {
                    eventsListContainer.appendChild( this.drawEvent(event) );
                    //eventsListContainer.appendChild( this.drawSkeletonEvent() );
                    addedEvents++;
                }
            }
        }

        if (!addedEvents) {
            this.setEventsListTitle(false); // Hide title

            if (!date1 && !date2)   // When fetching all year's events and no events available
                this.showEventsMessage('unscheduled');
            else
                this.showEventsMessage('no-events');
        } else {
            
            if (!date1 && !date2)
                this.setEventsListTitle();  // Show default title
            else
                this.setEventsListTitle(true,selDateTo || selDateFrom);  // Show past/future title
        }

        this.setArrowBtnVisibility();

        if (this.scrollbar)
            this.scrollbar.update();

        console.log('Events created -->',addedEvents)

        //return addedEvents;
    }

    showEventsMessage(type) {

        const messageContainer = this.eventsContainer.querySelector(this._el('events-message',true));

        switch(type) {
            case 'no-events':
                messageContainer.classList.remove('unscheduled');
                messageContainer.classList.add('no-events');
                break;
            case 'unscheduled':
                messageContainer.classList.remove('no-events');
                messageContainer.classList.add('unscheduled');
                break;
            case undefined:
                messageContainer.classList.remove('no-events','unscheduled');
                break;
        }
    }

    drawEvent(eventData) {

        console.log('+++ New Event:',eventData);

        const newEvent = this.eventTemplate.cloneNode(true);
        const { color } = this.getCategoryColors(eventData.eventType);
        const border = newEvent.querySelector('.elem-calendar-event__border');
        const bullet = newEvent.querySelector('.elem-calendar-event__category');
        const location = newEvent.querySelector('.elem-calendar-event__location');
        const date = newEvent.querySelector('.elem-calendar-event__date');
        const hours = newEvent.querySelector('.elem-calendar-event__hours');
        const title = newEvent.querySelector('.elem-calendar-event__title');
        const desc = newEvent.querySelector('.elem-calendar-event__description');
        const btnLink = newEvent.querySelector('.btn-link');
        const btnCal = newEvent.querySelector('.btn-calendar');

        if (color) {
            border.style.backgroundColor = color;
            bullet.style.backgroundColor = color;
        }

        if (eventData.location)
            location.textContent = eventData.location + ',';

        if (eventData.title)
            title.insertAdjacentHTML('afterbegin', eventData.title);

        if (eventData.formattedDate)
            date.insertAdjacentHTML('afterbegin', eventData.formattedDate);

        if (eventData.formattedEndDate && eventData.formattedDate != eventData.formattedEndDate)
            date.insertAdjacentHTML('beforeend', ' - ' + eventData.formattedEndDate);

        if (eventData.schedule)
            hours.insertAdjacentHTML('afterbegin', eventData.schedule);
        else
            hours.classList.add('hidden');
        
        if (eventData.description)
            desc.insertAdjacentHTML('afterbegin', eventData.description);
        else
            desc.classList.add('hidden');
        
        if (eventData.ctaDetail || eventData.servletEndpointIcs) {

            if (eventData.ctaDetail) {
                let btnText = btnLink.querySelector('span');
                btnText.textContent = eventData.ctaDetail.label || 'Discover';
                btnLink.setAttribute('href', eventData.ctaDetail.href || 'javascript:void(0);');
                if (eventData.ctaDetail.modelHashMap) {
                    btnLink.setAttribute('title', eventData.ctaDetail.modelHashMap.title || '');
                    btnLink.setAttribute('target', eventData.ctaDetail.modelHashMap.target || '_self');
                    btnLink.setAttribute('rel', eventData.ctaDetail.modelHashMap.rel || '');
                }
            } else {
                btnLink.remove();
            }

            if (eventData.servletEndpointIcs) {
                let btnText = btnCal.querySelector('span');
                btnText.textContent = eventData.ctaSaveTheDate || 'Save the date';
                btnCal.setAttribute('href', eventData.servletEndpointIcs);
                //btnCal.addEventListener('click', this._disableIcsLink);
            } else {
                btnCal.remove();
            }
        } else {
            newEvent
                .querySelector('.elem-calendar-event__cta-container')
                .classList.add('hidden');
        }

        newEvent.classList.remove('hidden');
        
        return newEvent;
    }

    disableIcsLink(ev) {

        if (ev.target.classList.contains('btn-calendar')) {

            ev.preventDefault();
            const cta = ev.target;

            if (!cta.timer) {

                cta.icslink = cta.icslink || cta.getAttribute('href');
                cta.href = '#';
                cta.setAttribute('disabled','true');
                cta.setAttribute('aria-disabled','true');

                cta.timer = setTimeout(() => {
                    cta.href = cta.icslink;
                    cta.removeAttribute('disabled');
                    cta.removeAttribute('aria-disabled');
                    cta.timer = null;
                }, 6000);

                window.open(cta.icslink,'_blank');
            }
        }
    }

    drawEventsSkeletonList() {

        const eventsListContainer = this.eventsContainer.querySelector('ul');
        eventsListContainer.innerHTML = "";

        for (let i=0; i <= this.EVENTS_SKELETON_CARDS_NUM; i++) {
            eventsListContainer.appendChild( this.drawSkeletonEvent() );
        }

        if (this.scrollbar)
            this.scrollbar.update();
    }

    cleanEventsList() {
        
        const eventsListContainer = this.eventsContainer.querySelector('ul');
        eventsListContainer.innerHTML = "";

        if (this.scrollbar)
            this.scrollbar.update();
    }

    drawSkeletonEvent() {
        // Adding random style skeleton event card
        const skEvent = this.eventTemplate.cloneNode(true);
        const styles = ['style1','style2','style3',''];
        const style = styles[Math.floor(Math.random() * styles.length)];

        skEvent.classList.add('skeleton-event');
        skEvent.classList.remove('hidden');
        if (style) skEvent.classList.add(style);
        
        return skEvent;
    }

    drawLegend() {

        if (this.legendCategories.size) {

            this.legendCategories.forEach((val) => {

                let item = document.createElement('li'),
                    text = document.createElement('span'),
                    bullet = document.createElement('em');
                
                this.legendContainer.appendChild(item);
                item.appendChild(bullet);
                item.appendChild(text);
                
                bullet.style.backgroundColor = val.color;
                item.classList.add(this._el('legend-category'));
                text.textContent += val.label;
            });
        }

        return false;
    }

    async drawCalendar() {

        this.calContainer.innerHTML = "";

        let weekday = new Date(this.activeYear, this.activeMonth, 1).getDay(),
            firstWeekDay = weekday ? weekday - 1 : 6,
            lastDate = this.getLastMonthDay(),
            currentDate = 1,
            currentWeekDay = 0,
            currentWeek = 0;
        
        // Draw title bar
        let year = this.abbrYear ? "'" + ("" + this.activeYear).substr(2,2) : this.activeYear,
            monthName = this.monthsName[this.activeMonth];

        this.calContainer.insertAdjacentHTML('beforeend',
            `<div class="${this._el('week-row')} ${this._el('header')}">
                <button class="${this._el('prev-month')}"><em class="icon-arrow_left"></em></button>
                <h3 class="${this._el('month-title')}">${monthName} ${year}</h3>
                <button class="${this._el('next-month')}""><em class="icon-arrow_right"></em></button>
            </div>`);

        this.setArrowBtnVisibility();

        // Draw day labels
        let dayNames = document.createElement('div');

        dayNames.className = `${this._el('week-row')} ${this._el('sub-header')}`;

        for (let i = 0; i < this.daysOfWeek.length; i++) {
            dayNames.insertAdjacentHTML('beforeend',
                `<div class="${this._el('day-header')}">
                    <div class="${this._el('day-header-cell')}">${this.daysOfWeek[i]}</div>
                </div>`);
        }

        this.calContainer.appendChild(dayNames);
        
        // Loop through weeks
        while (currentDate <= lastDate) {

            currentWeekDay = 0;
            
            // Draw a div for the week
            let week = document.createElement('div');
            week.className = `${this._el('week-row')} calweekid${currentWeek}`;

            // Draw days before the 1st of the month
            while (currentDate === 1 && currentWeekDay < firstWeekDay) {
                week.insertAdjacentHTML('beforeend',`<div class="${this._el('day-col')} ${this._el('blankday')}">
                        <div class="${this._el('day-multisel')}"></div>
                    </div>`);
                currentWeekDay++;
            }

            // Loop through days
            while (currentWeekDay < 7 && currentDate <= lastDate) {
                
                let isToday = currentDate === this.day && this.month === this.activeMonth && this.year === this.activeYear;

                // Draw day
                week.insertAdjacentHTML('beforeend',
                    `<div class="${this._el('day-col')}">
                        <div class="${this._el('day-cell')} ${this._el('day-cell')}${currentDate}${isToday ? ' today' : ''}" data-day="${currentDate}">
                            <span class="${this._el('date-label')}">${currentDate}</span>
                            <ul class="${this._el('day-categories')}"></ul>
                        </div>
                        <div class="${this._el('day-multisel')}"></div>
                    </div>`);

                currentDate++;
                currentWeekDay++;
            }

            if (currentDate-1 === lastDate && currentWeek === 3) {
                this.calContainer.appendChild(week);
                currentWeek++;
                // Inserting an extra blank week on february when weeks are less than 5
                week = document.createElement('div');
                week.className = `${this._el('week-row')} calweekid${currentWeek}`;
                currentWeekDay = 0;
            }

            // Draw empty days after last day of month
            while (currentWeekDay < 7) {
                week.insertAdjacentHTML('beforeend',`<div class="${this._el('day-col')} ${this._el('blankday')}">
                        <div class="${this._el('day-multisel')}"></div>
                    </div>`);
                currentWeekDay++;
            }

            this.calContainer.appendChild(week);
            currentWeek++;
        }

        return this._setCalendarEvents();
    }

    _setCalendarEvents() {

        document.removeEventListener('bpMobileTablet', this._removeCustomScrollBar);
        document.addEventListener('bpMobileTablet', this._removeCustomScrollBar);
        document.removeEventListener('bpDesktop', this._addCustomScrollBar);
        document.addEventListener('bpDesktop', this._addCustomScrollBar);

        let prevMonth = this.calContainer.querySelector(this._el('prev-month',true));
        prevMonth.removeEventListener('click', this._setPreviousMonth);
        prevMonth.addEventListener('click', this._setPreviousMonth);

        let nextMonth = this.calContainer.querySelector(this._el('next-month',true));
        nextMonth.removeEventListener('click', this._setNextMonth);
        nextMonth.addEventListener('click', this._setNextMonth);

        this.calContainer.removeEventListener('click', this._dayClicked);
        this.calContainer.addEventListener('click', this._dayClicked);

        this.eventsContainer.removeEventListener('click', this._disableIcsLink);
        this.eventsContainer.addEventListener('click', this._disableIcsLink);
        
        return this;
    }

    compareDates(date1, date2) {

        try {
            let dateA = new Date(date1);
            let dateB = new Date(date2);

            return {
                low: dateA.getTime() > dateB.getTime() ? dateB : dateA,
                high: dateA.getTime() > dateB.getTime() ? dateA : dateB,
            }
        } catch(err) {
            console.warn('Invalid Date comparison:',err);
            return false;
        }
    }

    compareNumbers(num1, num2) {

        const number1 = parseInt(num1);
        const number2 = parseInt(num2);

        return {
            'low': +number1 > +number2 ? number2 : number1,
            'high': +number1 > +number2 ? number1 : number2
        }
    }

    convertObjToDate(dateObj,timeObj) {
        return new Date(dateObj.year, dateObj.month, dateObj.dayOfMonth, timeObj.hourOfDay, timeObj.minute);
    }
}

register.registerClass('.widget-calendar-events', CalendarEvents);
