

///////////////////////////////////////////////////
//////////// SHARING UTILS
///////////////////////////////////////////////////
export const shareTwitter = (data) => {

    let twitterData = '',
        twitterUrl;

    if (data.text) {
        twitterData = data.title + ' / ' + data.text + "&url=" + data.url;
    } else {
        twitterData = data.title + "&url=" + data.url;
    }

    twitterUrl = "https://twitter.com/intent/tweet?text=" + twitterData;

    window.open(twitterUrl,'share-twitter','width=650,height=500');
}

export const shareFacebook = (data) => {

    let appID = '382518490051185';
    let facebookUrl = `https://www.facebook.com/dialog/share?app_id=${appID}&href=${data.url}&display=popup`;

    window.open(facebookUrl,'share-facebook','width=650,height=500');
}

export const shareLinkedin = (data) => {

    let linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${data.url}`;

    window.open(linkedinUrl,'share-linkedin');
}

export const shareXing = (data) => {

    const xingUrl = `https://www.xing.com/social/share/spi?url=${data.url}`;

    window.open(xingUrl,'share-xing');
}

export const shareEmail = (data) => {

    let emailData = '',
        emailUrl;

    if (data.text) {
        emailData = data.text + '%0D%0A%0D%0A';
    }

    // '%20' for cc and bbc is uqual as whitespace
    emailData += "&subject=" + data.title + "&body=" + data.url;
    emailUrl = "mailto:?cc=%20&bcc=%20" + emailData;
    
    window.open(emailUrl,'share-email');
}

export const copyToClipboard = async (data) => {
    const clipboardText =  await import("clipboard-polyfill/text");
    let clipboardApi = navigator.clipboard || clipboardText;

    try {
        clipboardApi
            .writeText( decodeURIComponent(data.url) )
            .then(() => {
                console.log('Card info successfully copied to clipboard!')
                return true;
            })
            .catch(() => {
                console.error('Card info NOT copied to clipboard!')
            });
            
    } catch(err) {
        console.log('Clipboard write not available!');
    }

    return false;
}
