import Component from '../../libs/component';
import { register } from '../../libs/register';

class ScrollToTop extends Component {

    constructor(container) {
        super('elem-scroll-to-top');

        this.button = container.querySelector('button');

        this.button.addEventListener( 'click', (ev) => {
            ev.preventDefault();

            this._scrollTop();
        });

        let ticking = false;

        document.addEventListener('scroll', () => {

            if (!ticking) {
                window.requestAnimationFrame( () => {
                    this._trackScroll();
                    ticking = false;
                });

                ticking = true;
            }
        });
    }

    _scrollTop() {

        // Scroll to top logic
        document.documentElement.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    // Show or hide scroll to top button
    _trackScroll() {
        let rootElement = document.documentElement;
        
        if(rootElement.scrollTop >  rootElement.clientHeight) {
            document.querySelector('.elem-scroll-to-top').classList.add("show");
        } else {
            document.querySelector('.elem-scroll-to-top').classList.remove("show");
        }
    }
}

register.registerClass('.elem-scroll-to-top', ScrollToTop);