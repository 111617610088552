import Component from '../../libs/component';
import { register } from '../../libs/register';

class AccordionPanel extends Component {
    constructor(container) {
        super('widget-accordion-panel');

        this.accordion = container;
        this.btnAccordion =  container.querySelector('.widget-accordion-panel__opener button');
        this.accordionCollapse = container.querySelector('.widget-accordion-panel__collapse');
        this.accordionState = this.accordionCollapse.dataset.collapse;

        this._setAccordion();
    }

    _setAccordion() {
         
        this.btnAccordion.addEventListener('click', (ev) => {
            ev.preventDefault();
            this._expandAccordion(ev);
        });
    }

    _expandAccordion() {
        if (this.accordionState === "true") {
            this._closeAccordion();
        } else {
            this._openAccordion();
        }
    }

    // Open Accordion
    _openAccordion() {
        let _height = this.accordionCollapse.querySelector('.widget-accordion-panel__content').scrollHeight;

        this.accordionCollapse.style.height = _height + 'px';
        this.accordionCollapse.classList.add('show');
        this.accordionCollapse.setAttribute('data-collapse', 'true');
        this.btnAccordion.setAttribute('aria-expanded', 'true');

        this.accordionState = this.accordionCollapse.dataset.collapse;
    }

    // Close Accordion
    _closeAccordion() {

        this.accordionCollapse.style.height = null ;
        this.accordionCollapse.classList.remove('show');
        this.accordionCollapse.setAttribute('data-collapse', 'false');
        this.btnAccordion.setAttribute('aria-expanded', 'false');
        
        this.accordionState = this.accordionCollapse.dataset.collapse;
    }

}

register.registerClass('.widget-accordion-panel', AccordionPanel);