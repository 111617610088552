import Component from '../../libs/component';
import { register } from '../../libs/register';

class Carousel extends Component {
    constructor(container) {
        super('widget-carousel');

        this.swiperExternalDiv = container;
        this.swiperContainer = container.querySelector(`${this._el('container', true)}`);
        this.autoHeightComponent = true;
        this.desktopResolution = false;
        this._initSwiper(container);
    }

    async _initSwiper() {
        if (this.swiper) {
            this.swiperContainer.style.height = '';
            this.swiperContainer.classList.remove(`${this._el('container--fixedHeight', false)}`);
            this.swiperContainer.querySelectorAll('.widget-hero__container').forEach((heroSingle) => {
                heroSingle.style.removeProperty('min-height');
            });
            this.autoHeightComponent = true;
            this._destroySwiper();
        }

        let desktopFixedHeight = this.swiperExternalDiv.dataset.heightDesktop;
        let tabletFixedHeight = this.swiperExternalDiv.dataset.heightTablet;
        let mobileFixedHeight = this.swiperExternalDiv.dataset.heightMobile;

        if (window.deviceBreakpoints.bpDesktop.matches === true && (desktopFixedHeight && desktopFixedHeight != '')) {
            this.swiperContainer.style.height = parseInt(desktopFixedHeight) + 'px';
            this.autoHeightComponent = false;
            this.desktopResolution = true;
            this.swiperContainer.classList.add(`${this._el('container--fixedHeight', false)}`);
        }

        if (window.deviceBreakpoints.bpTablet.matches === true && (tabletFixedHeight && tabletFixedHeight != '')) {
            this.swiperContainer.style.height = parseInt(tabletFixedHeight) + 'px';
            this.autoHeightComponent = false;
            this.desktopResolution = false;
            this.swiperContainer.classList.add(`${this._el('container--fixedHeight', false)}`);
        }

        if (window.deviceBreakpoints.bpMobile.matches === true && (mobileFixedHeight && mobileFixedHeight != '')) {
            this.swiperContainer.style.height = parseInt(mobileFixedHeight) + 'px';
            this.autoHeightComponent = false;
            this.desktopResolution = false;
            this.swiperContainer.classList.add(`${this._el('container--fixedHeight', false)}`);

        }


        const Swiper = await import('swiper');
        this.swiper = new Swiper.default(this.swiperContainer, {
            modules: [Swiper.Autoplay, Swiper.EffectFade, Swiper.Navigation, Swiper.Pagination, Swiper.Lazy],
            slidesPerView: '1',
            speed: 700,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            preloadImages: false,
            lazy: {
                enabled: true,
                loadPrevNext: false,
                checkInView: true,
                loadOnTransitionStart: true,
                loadedClass: 'media-picture__img--loaded',
            },
            autoHeight: this.autoHeightComponent,
            roundLengths: true,
            observer: true,
            watchOverflow: true,
            preventInteractionOnTransition: true,
            simulateTouch: false,
            nested: true,
            loop: true,
            autoplay: {
                delay: 7000,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            on: {
                lazyImageLoad: () => {
                    //console.log('LAZY IMAGE INIT')
                },
                lazyImageReady: () => {
                    //console.log(' + LAZY IMAGE LOADED')
                },
                slideChange: (swiper) => {
                    //const slides = [...swiper.el.querySelectorAll('.swiper-slide:not(.swiper-slide-active)')];
                    const activeSlide = swiper.el.querySelector('.swiper-slide-active');

                    if (activeSlide) {
                        let buttons = activeSlide.querySelectorAll('.elem-cta-modal');

                        buttons.forEach((el) => {
                            if (!el.objReference) register.applyTo(el);
                        });
                    }
                },
            },
        });

        if(this.swiperContainer.classList.contains(`${this._el('container--fixedHeight', false)}`)) {
            this.swiperContainer.style.removeProperty('min-height');
            if(this.desktopResolution) {
                this.swiperContainer.querySelectorAll('.widget-hero__container').forEach((heroSingle) => {
                    heroSingle.style.minHeight = desktopFixedHeight + 'px';
                });
            }
        }

        document.addEventListener('bpDesktop', () => {
            this._initSwiper();
        });
        document.addEventListener('bpTablet', () => {
            this._initSwiper();
        });

        document.addEventListener('bpMobile', () => {
            this._initSwiper();
        });
    }

    _pauseSlideshow() {
        if (this.swiper)
            if (this.swiper.autoplay.running) {
                this.swiper.autoplay.stop();
                console.info('Carousel slideshow on PAUSE');
            }
    }

    _playSlideshow() {
        if (this.swiper)
            if (!this.swiper.autoplay.running) {
                this.swiper.autoplay.start();
                console.info('Carousel slideshow STARTED');
            }
    }

    _destroySwiper() {
        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = undefined;
        }
    }
}

register.registerClass('.widget-carousel', Carousel);
