import Component from '../../libs/component';
import { register } from '../../libs/register';

class IconAnim extends Component {

    constructor(container) {
        super('elem-icon-anim');

        if (window.deviceBreakpoints.bpDesktop.matches === false)
            return;

        this.el = container;
        this.activation = container.dataset.activation || 'mouseover';
        this.noCompletion = !!container.dataset.nocompletion;

        this._init();
    }

    async _init() {

        let iconName;

        for (let cl of this.el.classList) {
            if (cl.startsWith('icon-')) {
                iconName = cl.replace('icon-', '');
            }
        }

        if (!this.lottie && iconName) {
            await this._loadIcons();

            if (this.el.classList.contains('dark'))
                iconName += 'Dark';

            let params = {
                container: this.el,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: this.iconsData.get(iconName)
            };

            const Lottie = await import('lottie-web/build/player/lottie_light');
            this.lottie = Lottie.default.loadAnimation(params);
            this.lottie
                .addEventListener('DOMLoaded', () => {

                    this.el.classList.add(`${this._mod('init')}`);

                    try {
                        this.el.style.width = this.lottie.renderer.globalData.compSize.w + 'px';
                        this.el.style.height = this.lottie.renderer.globalData.compSize.h + 'px';
                    } catch (err) {
                        console.log('Lottie set icon size FAILED', err);
                    }

                    // Go to last frame, play and stop
                    this.lottie.goToAndPlay(this.lottie.getDuration(true), true);
                });

            this.el
                .parentElement
                .addEventListener(this.activation, () => this._play());
        }
    }

    _play() {

        if (!this.noCompletion)
            if (!this.lottie.isPaused)
                return;

        if (this.lottie)
            this.lottie.goToAndPlay(0, true);
    }

    async _loadIcons() {
        this.iconsData = new Map();
        this.iconsData.set('globe_closed', await import('../../assets/lottie/globe_closed.json'));
        this.iconsData.set('letter', await import('../../assets/lottie/letter_light.json'));
        this.iconsData.set('letterDark', await import('../../assets/lottie/letter_dark.json'));
        this.iconsData.set('book', await import('../../assets/lottie/book_light.json'));
        this.iconsData.set('bookDark', await import('../../assets/lottie/book_dark.json'));
        this.iconsData.set('pdf', await import('../../assets/lottie/pdf_light.json'));
        this.iconsData.set('pdfDark', await import('../../assets/lottie/pdf_dark.json'));
        this.iconsData.set('play', await import('../../assets/lottie/play_light.json'));
        this.iconsData.set('playDark', await import('../../assets/lottie/play_dark.json'));
        this.iconsData.set('microphone', await import('../../assets/lottie/microphone_light.json'));
        this.iconsData.set('microphoneDark', await import('../../assets/lottie/microphone_dark.json'));
        this.iconsData.set('download', await import('../../assets/lottie/download_light.json'));
        this.iconsData.set('downloadDark', await import('../../assets/lottie/download_dark.json'));
    }
}

register.registerClass('.elem-icon-anim', IconAnim);