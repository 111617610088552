import Component from '../../libs/component';
import { register } from '../../libs/register';



class LayoutMasonry extends Component {

    constructor(container) {
        super('layout-masonry');

        this.container = container.querySelector(this._el('container',true));
        this.items = container.getElementsByClassName(this._el('item'));

        this._init();
    }

    async _init() {
        const Macy = await import('macy');
        if (this.container)
            this.masonry = new Macy.default({
                container: this.container,
                waitForImages: false,
                mobileFirst: true,
                margin: 24,
                columns: 1,
                breakAt: {
                    1024: {
                        margin: 16,
                        columns: 3,
                    },
                    768:{
                        margin: 24,
                        columns: 2,
                    },
                }
            });
    }

    addItems(items,updateLayout=true) {

        if (items) {

            let newItems = items;

            if (!Array.isArray(newItems))
                newItems = [items];

            newItems.forEach((item) => {
                this.container.appendChild(item);
            });

            if (updateLayout)
                this.update();
        }
    }

    removeItems(className,updateLayout=true) {

        className = className || '.elem-card';
        let items = this.container.querySelectorAll(className);

        items.forEach((item) => {
            this.container.removeChild(item);
        });

        if (updateLayout)
            this.update();
    }

    itemsCount() {
        let items = this.container.getElementsByClassName('elem-card');
        return items ? items.length : 0;
    }

    update() {

        if (this.masonry) {
            let event = new CustomEvent('resize', { bubbles: true });
            window.dispatchEvent(event);
            this.masonry.recalculate(true);
            return true;
        }

        return false;
    }
}

register.registerClass('.layout-masonry', LayoutMasonry);
