import Component from '../../libs/component';
import { register } from '../../libs/register';

const tabId = window.location.href.split('tabId=').pop().split('&')[0];

class PastEvents extends Component {

    constructor(container) {
        super('widget-past-events');

        this.container = container;
        this.tabGroup = this.container.querySelector(`${this._el('tab-group',true)}`);
        this.tabItems = [...this.tabGroup.children];
        this.tabContent = this.container.querySelector(`${this._el('tab-content',true)}`);
        this.tabPane = [...this.tabContent.children];
        this.loadMore = this.container.querySelectorAll(`${this._el('load-more',true)}`);

        this.tabButtonPaneSelected = document.getElementById(tabId);
        this.tabButtonSelected = document.querySelector('[data-target="#' + tabId + '"]');

        this.PAST_EVENTS_SKELETON_CARDS_NUM = 9;

        this._init();
        this._setTab();

        this.tabItems.forEach((el) => {
            el.addEventListener('click', (ev) => {
                this._openTab(ev);
            });
        });

        window.addEventListener('load', () => this. _scrollToTab() );
        window.addEventListener('visibilitychange', () => this. _scrollToTab() );

    }

    _scrollToTab() {
        if (this.tabButtonSelected) {
            this.tabButtonSelected.scrollIntoView();
        }
    }

    _removeActiveTabs() {
        this.tabPane.forEach((el) => {
            el.classList.remove('active');
        });

        this.tabItems.forEach((el) => {
            el.classList.remove('active');
        });
    }
    
    _init() {

        this.endpoint =  this.tabGroup.closest('.widget-past-events__container').dataset.url;

        if (this.loadMore) {

            this.loadMore.forEach((el) => {
                el.querySelector('button').addEventListener('click', (ev) => {
                    ev.preventDefault();

                    el.classList.add('disabled')
                    this._fetchEventsItems(this._getOffset());
                });
            });
        }

    }

    _setTab() {
        if (this.tabItems) {
            this._removeActiveTabs();

            if (this.tabButtonSelected) {
                this.tabButtonPaneSelected.classList.add('active');
                this.tabButtonSelected.classList.add('active');
            }
            else {
                this.tabPane[0].classList.add('active');
                this.tabItems[0].classList.add('active');
            }
        }
    }

    _openTab(el){

        let _button = el.currentTarget;
        let _tabTarget = _button.dataset.target;
        
        this._removeActiveTabs();

        document.querySelector(_tabTarget).classList.add("active");
   
        _button.classList.add("active");

        console.debug(_button,_tabTarget);
        
    }

    _fetchEventsItems(offset=0) {
        let year = this.container.querySelector('.widget-past-events__btn.active').innerText;
     
        let request = new XMLHttpRequest();
        let resPath = this.endpoint ? this.endpoint : '/assets/files/past-events-test.json' ;
        resPath += '?year=' + year;

        
        if (offset) {
            resPath += `&offset=${offset}`;
        }

        this._addSkeletonCards();

        console.log('*** Request path:',resPath,offset);

        request.open('GET',resPath,true);
        request.timeout = 10000;
        request.responseType = 'json';
        request.onload = () => {

            if (request.status === 200) {
                try {
                    let dataObj = (typeof request.response === 'string' || request.response instanceof String) ? JSON.parse(request.response) : request.response;
                    
                    if (!dataObj.itemsLeft) {
                        this._showLoadMoreButton(false);
                    } else {
                        this._showLoadMoreButton();
                    }

                    this._insertCards(dataObj);
                
                } catch(err) {
                    console.warn('Past events list fetch ERROR:',err);
                }
            }

            this._removeSkeletonCards();
        };

        request.onerror = () => {
            console.warn('Past events list network ERROR');
        };
        
        request.send();
    }

    _insertCards(dataObj) {

        this.tabPaneActive = this.tabContent.querySelector('.widget-past-events__tab-pane.active');

        if (dataObj.events) {

            dataObj.events.forEach((event) => {

                let cardItem = document.createElement('div');
                    cardItem.classList.add('elem-card');

                let cardContainer = document.createElement('div');
                    cardContainer.classList.add('elem-card__container');
                    cardItem.appendChild(cardContainer);

                if (event.ctaDetail) {
                    let link = document.createElement('a');
                        link.classList.add('elem-card__overlay');
                        link.href = event.ctaDetail.modelHashMap.href;

                        if(event.ctaDetail.modelHashMap.target) {
                            link.target = event.ctaDetail.modelHashMap.target;
                        }

                        if (dataObj.ctaDetailLabel) {
                            link.title = dataObj.ctaDetailLabel;
                        } else if(event.ctaDetail.modelHashMap.title) {
                            link.title = event.ctaDetail.modelHashMap.title;
                        }
                        cardContainer.appendChild(link);
                }

                let cardContent = document.createElement('div');
                    cardContent.classList.add('elem-card__content');
                    cardContainer.appendChild(cardContent);

                let cardDetails = document.createElement('div');
                    cardDetails.classList.add('elem-card__details');
                    cardContent.appendChild(cardDetails);

                if (event.location || event.formattedDate) {
                    let decodedDate = event.formattedDate ? decodeURIComponent(event.formattedDate) : '';
                    let decodedEndDate = event.formattedEndDate ? decodeURIComponent(event.formattedEndDate) : '';
                    let dataText = event.location || '';
                    let preTitle = document.createElement('div');
                        preTitle.appendChild(document.createTextNode(dataText));
                        preTitle.classList.add('elem-card__pretitle');
                        preTitle.innerHTML += event.location && decodedDate ? ', ' : '';
                        preTitle.innerHTML += decodedDate || '';
                        if(event.formattedEndDate && event.formattedDate != event.formattedEndDate) {
                            preTitle.innerHTML += ' - ' + decodedEndDate;
                        }
                        cardDetails.appendChild(preTitle);
                }
                
                if(event.schedule) {
                    let schedule = document.createElement('div');
                    schedule.classList.add('elem-card__hours');

                    let icon = document.createElement('em');
                    icon.classList.add('icon-clock');

                    let hours = document.createElement('div');
                    hours.classList.add('elem-card__hours-text');
                    hours.innerHTML = event.schedule;
                    
                    schedule.appendChild(icon);
                    schedule.appendChild(hours);

                    cardDetails.appendChild(schedule);
                }


                if (event.title) {
                    let title = document.createElement('div');
                        title.innerHTML = event.title ;
                        title.classList.add('elem-card__title');
                        cardDetails.appendChild(title);
                }

                if (event.description) {
                    let description = document.createElement('div');
                        description.innerHTML = event.description;
                        description.classList.add('elem-card__description');
                        cardDetails.appendChild(description);
                }
                
                if (event.ctaDetail) {
                    let cta = document.createElement('span');
                    cta.classList.add('btn-text-link');
                    
                    if (dataObj.ctaDetailLabel) {
                        cta.innerHTML = dataObj.ctaDetailLabel;
                    } else {
                        cta.innerHTML = event.ctaDetail.label;
                    }
                    

                    let ctaIcon = document.createElement('em');
                    ctaIcon.classList.add('icon-arrow_right');

                    cta.appendChild(ctaIcon);
                    cardDetails.appendChild(cta);
                }
                
                this.tabPaneActive.querySelector('.layout-cards__container').appendChild(cardItem);
            });
        }

    }
    
    _addSkeletonCards() {
        this.tabPaneActiveContainer = this.tabContent.querySelector('.widget-past-events__tab-pane.active .layout-cards__container');

        for (let i=0; i < this.PAST_EVENTS_SKELETON_CARDS_NUM; i++) {

            let cardSkeleton =
                `<div class="elem-card__container">
                    <div class="elem-card__content">
                        <div class="elem-card__details">
                            <div class="elem-card__pretitle"></div>
                            <div class="elem-card__hours"></div>
                            <div class="elem-card__title"></div>
                            <div class="elem-card__description"></div>
                        </div>
                    </div>
                </div>`;

            let cardItem = document.createElement('div');
                cardItem.className = 'elem-card skeleton-card';
                cardItem.innerHTML = cardSkeleton;
            
            this.tabPaneActiveContainer.append(cardItem);
        }
    }

    _removeSkeletonCards() {
        const removeElements = (elms) => elms.forEach(el => el.remove());

        removeElements(this.tabPaneActiveContainer.querySelectorAll('.skeleton-card'));
    }

    _showLoadMoreButton(show=true) {
        this.tabPaneActive =   this.tabContent.querySelectorAll('.widget-past-events__tab-pane.active');
        this.loadMoreBtn = this.tabContent.querySelector('.widget-past-events__tab-pane.active .widget-past-events__load-more');

        if (this.loadMoreBtn) {

            if (show) {
                this.loadMoreBtn.classList.remove('hidden');
                this.loadMoreBtn.querySelector('button').classList.remove('disabled');
            } else {
                this.loadMoreBtn.classList.add('hidden');
            }
        }
    }

    _getOffset(){
        this.cardContainer =   this.tabContent.querySelectorAll('.widget-past-events__tab-pane.active .elem-card');
        const offset = this.cardContainer.length;
         
        return offset;
    }
}

register.registerClass('.widget-past-events', PastEvents);