import Component from '../../libs/component';
import { register } from '../../libs/register';

class QuickLinks extends Component {
    constructor(container) {
        super('elem-quick-links');

        this.QuickLinksContainer = container.querySelector('.elem-quick-links__items');

        document.addEventListener('bpMobileTablet', () => this._initSwiper());
        document.addEventListener('bpDesktop', () => this._destroySwiper());

        if (window.deviceBreakpoints.bpMobileTablet.matches === true) this._initSwiper();
    }

    async _initSwiper() {
        if (!this.swiper) {
            const Swiper = await import('swiper');
            this.swiper = new Swiper.default(this.QuickLinksContainer, {
                slidesPerView: '1',
                grabCursor: true,
                observer: true,
                watchOverflow: true,
                spaceBetween: 32,
            });
        } else {
            this.swiper.update();
        }
    }

    _destroySwiper() {
        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = undefined;
        }
    }
}

register.registerClass('.elem-quick-links', QuickLinks);
