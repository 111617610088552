import Component from '../../libs/component';
import Pristine from 'pristinejs/dist/pristine.min';
import { register } from '../../libs/register';
import { request } from '../../libs/utils';
import { analytics } from '../../libs/analytics';



class FormInfo extends Component {
    constructor(container) {
        super('widget-form-info');

        this.container = container;
        this.form = container.querySelector('form');
        this.formId = this.form.id;
        this.formServletPath = this.form.dataset.url || '';
        this.formFieldsContainer = this.form.querySelector(`${this._el('form-fields',true)}`);

        this.siteKey = window.corp ? window.corp.recaptchasitekey || '' : '';
        this.recaptchaEnabled = this.siteKey ? Boolean(this.form.dataset.recaptchaEnabled) : false;

        this.btnSend = this.container.querySelector(`${this._el('form-btn',true)}[data-role=send]`);

        this._initForm();

        window.form = {};
        window.form.showResult = this._showResult.bind(this);
        window.form.hideResult = this._hideResult.bind(this);
    }

    _initForm() {
        console.log('Form init');

        if (!this.container.classList.contains('init')) {
        
            const formFooter = this.form.querySelector(`${this._el('form-footer',true)}`);

            formFooter.addEventListener('click', ({target:btn}) => {

                switch(btn.dataset.role) {
                    case 'send':
                        this._sendFormData();
                        break;
                    case 'back':
                        this._goBack();
                        break;
                }
            });

            this._initValidation();
            this._initRecaptcha();

            this.container.classList.add('init');
        }
    }

    _initValidation() {

        const formOptions = {
            classTo: 'widget-form-info__input',
            errorClass: 'error',
            successClass: 'valid',
            errorTextParent: 'widget-form-info__input',
            errorTextTag: 'div',
            errorTextClass: 'error-text'
        };
        const customSelect = this.form.querySelector(`.elem-custom-select`);
        const customSelectElem = customSelect.querySelector(`select[name=interest]`);
        const customEmailElem = this.form.querySelector(`[name=email]`);

        Pristine.addValidator('ansi-email', function(value) {
            //console.log(value);
            const regexp = /\S+@[a-zA-Z0-9]+(-[A-Za-z0-9]+)?\.\S+/; // Accepts latin character only after @ (no phunycodes or other alphabets)
            if (value.match(regexp))
                return true;
            return false;
        }, customEmailElem.dataset.pristineAnsiEmailMessage, 10, true);

        this.formValidate = new Pristine(this.form, formOptions, false);

        this.formValidate.addValidator(customSelectElem, function(value) {
            if ((this.getAttribute('required') === null) || (value != 'placeholder')) return true;
            return false;
        }, customSelectElem.dataset.pristineSelectMessage, 1, true);

        customSelect.addEventListener('selectOpen', ({target}) => {
            const cSelect = target.querySelector(`select`);
            this._sendInteractionAnalytics(cSelect,'focus');
        });
        
        customSelect.addEventListener('selectClosed', ({target}) => {
            const cSelect = target.querySelector(`select`);
            this._validateFormInput(cSelect);
            this._checkInputError(cSelect);
        });

        const lettersFields = [...this.form.querySelectorAll(`[name=name],[name=surname]`)];
        lettersFields.forEach((item) => {
            item.addEventListener('keydown', (ev) => {
                if (!isNaN(parseFloat(ev.key)) && isFinite(ev.key)) // Blocks numeric values
                    ev.preventDefault();
            });
        });

        const numberFields = [...this.form.querySelectorAll(`input[type=tel]`)];
        numberFields.forEach((item) => {
            item.addEventListener('keydown', (ev) => {
                if (isNaN(parseFloat(ev.key)) && !isFinite(ev.key)) // Insert numeric values only
                    if (ev.key != "Backspace" && ev.key != "Delete" && ev.key != "ArrowLeft" && ev.key != "ArrowRight" && ev.key != "Tab")
                        ev.preventDefault();
            });
        });

        this.form.addEventListener('change', ({target}) => {
            this._validateFormInput(target);
            this._checkInputError(target);
        });

        this.form.addEventListener('focusin', ({target}) => {
            if (target.nodeName !== 'INPUT') return;

            const focusClear = () => {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                target.removeEventListener('focusout', focusClear);
            };

            target.addEventListener('focusout', focusClear);

            let timeout = setTimeout(() => {
                focusClear();
                this._sendInteractionAnalytics(target,'focus');
            }, 250);
        });
    }

    _validateFormInput(input) {

        if (this.formValidate) {
            this.formValidate.validate(input);
            this._enableSendButton( this._isFormValid() );
        }
    }

    _isFormValid() {

        if (this.formValidate) {
            const formFields = this.form.querySelectorAll(`${this._el('input',true)} input, ${this._el('input',true)} select`);
            return this.formValidate.validate(formFields,true);
        }

        return false;
    }

    _enableSendButton(enable = true) {

        if (enable) {
            this.btnSend.removeAttribute('disabled');
            this.btnSend.classList.remove('disabled');
        } else {
            this.btnSend.setAttribute('disabled','disabled');
            this.btnSend.classList.add('disabled');
        }
    }

    _hideButtons(hide = true, btnRole = null) {

        const buttons = [...this.form.querySelectorAll(`button[data-role${ btnRole ? '=' + btnRole : '' }]`)];

        buttons.forEach((item) => {
            hide ? item.classList.remove('active') : item.classList.add('active');
        });
    }

    _showForm() {
        this.formFieldsContainer.classList.remove('hidden');
    }

    _hideForm() {
        this.formFieldsContainer.classList.add('hidden');
    }

    _goBack() {
        this._resetRecaptcha();
        this._resetPrivacy();
        this._hideResult();
        this._hideButtons(false,'send');
        this._hideButtons(true,'back');
    }

    async _sendFormData() {

        console.log('SENDING FORM DATA...')

        const validatedFields = [...this.form.querySelectorAll(`${this._el('input',true)}.valid input, ${this._el('input',true)}.valid select`)];
        const formData = new FormData();
        let recaptchaResponse = true;

        this._submitButtonSending();
        this._enableSendButton(false);

        if (this.recaptchaEnabled && window.grecaptcha)
            recaptchaResponse = await this._checkRecaptcha(); // A returned Promise or False means rejected status

        if (recaptchaResponse === true || typeof recaptchaResponse === 'string') {
            formData.append('g-recaptcha-response',recaptchaResponse);

            validatedFields.forEach((el) => {
                //console.log(el.name, el.value);
                let value;
                if (el.type == 'checkbox')
                    value = el.value == 'on';
                else
                    value = el.value;

                formData.append(el.name, value);
            });

            const requestData = {
                method: 'POST',
                url: this.formServletPath,
                body: formData
            };
            
            request(requestData).
                then((resolve) => {
                    console.log('Send Form data SUCCESS',resolve.response);
                    this._onFormDataSent('success');
                    this._sendInteractionAnalytics(this.form,'send',true);
                })
                .catch((reject) => {
                    console.log(`Send Form Error: ${reject.status} / ${reject.response}`);
                    this._onFormDataSent('error');
                    this._sendInteractionAnalytics(this.form,'send',false,`Send Form Error: ${reject.status} / ${reject.response}`);
                    this._sendErrorAnalytics(this.form,'form',reject.status,'server',true);
                });
        } else {

            console.log('Send FormData Recaptcha Error');
            this._onFormDataSent('error');
            this._sendInteractionAnalytics(this.form,'send',false,'Recaptcha Error');
            this._sendErrorAnalytics(this.form,'form','','recaptcha',true);
        }
    }

    _onFormDataSent(result) {
        this._showResult(result);
        this._submitButtonRevert();
        this._hideButtons(true,'send');
        if (result == 'error') this._hideButtons(false,'back');
    }

    _showResult(responseClass) {

        const resultBlocks = [...this.form.querySelectorAll(`${this._el('form-result',true)}`)];

        resultBlocks.forEach((el) => {
            el.classList.remove('active');
            if (el.matches(`.${responseClass}`))
                el.classList.add('active');
        });

        this._hideForm();
    }

    _hideResult() {
        this._showResult();
        this._showForm();
    }

    _initRecaptcha() {

        const recaptchaContainer = this.form.querySelector('.recaptcha-container .g-recaptcha');

        if (this.siteKey && recaptchaContainer && window.grecaptcha)
            window.grecaptcha.ready(() => {
                this.gRecaptcha = window.grecaptcha.render(recaptchaContainer, {
                    'sitekey': this.siteKey,
                    //'badge': 'inline',
                    //'size': 'invisible'
                });
            });
    }

    async _checkRecaptcha() {
        console.log('FORM RECAPTCHA CHECK in progress...');

        try {
            return window.grecaptcha
                .execute(this.siteKey, {action: 'submit'})
                .then((token) => {
                    console.log('FORM RECAPTCHA RESPONSE:', token);
                    return token;
                })
                .catch((err) => console.warn(err));
        } catch(err) {
            console.warn(err);
            return false;
        }
    }

    _resetRecaptcha() {
        try {
            if (this.gRecaptcha !== null && this.gRecaptcha !== undefined)
                window.grecaptcha.reset(this.gRecaptcha);
            else
                window.grecaptcha.reset();
        } catch(err) {
            console.warn(err);
        }
    }

    _resetPrivacy() {
        const privacyCheckbox = this.form.querySelector('[name=privacy]');
        privacyCheckbox.removeAttribute('checked');
        privacyCheckbox.checked = false;
        //privacyCheckbox.value = '';
        this._validateFormInput(privacyCheckbox);
    }

    _submitButtonSending() {
        this.btnSend.classList.add('sending');
        this.btnSend.textContent = this.btnSend.dataset.sendingText;
    }

    _submitButtonRevert() {
        this.btnSend.classList.remove('sending');
        this.btnSend.textContent = this.btnSend.dataset.defaultText;
    }

    _checkInputError(input) {
        const inputContainer = input.closest(`${this._el('input',true)}.error`);
        
        if (inputContainer)
            this._sendErrorAnalytics(input,'input');
    }

    _sendInteractionAnalytics(target,type,success=null,msg=null) {
        
        let payload = {
            'event': 'form_interaction',
            'form': {
                'action': type,
                'element': target.id,
                'send': {
                    'result': null,
                    'message': null
                }
            }
        };

        if (success !== null) {
            payload.form.send.result = success ? 1 : 0;
            payload.form.send.message = msg;
        }

        analytics.sendData(payload);
    }

    _sendErrorAnalytics(target,type='input',code='',reason='',fatal=false) {

        const payload = {
            'event': 'custom_error',
            'language': document.documentElement.lang,
            'error': {
                'type': type,
                'code': code,
                'description': '',
                'message': null,
                'field': '',
                'isFatal': fatal
            }
        },
        inputContainer = target.closest(`${this._el('input',true)}`);

        if (type === 'input') {
            payload.error.field = target.getAttribute('name') || target.getAttribute('id');
            payload.error.message = inputContainer.querySelector('.error-text').textContent.trim() || null;
        } else {
            const errorResultBlock = target.querySelector(`${this._el('form-result',true)}.error`);
            payload.error.message = errorResultBlock
                                        .textContent
                                        .replace(/[\n\r]+|[\s]{2,}/g, ' ')
                                        .trim();
        }

        if (reason) {
            payload.error.type = 'backend';

            if (reason == 'recaptcha')
                payload.error.description = 'Recaptcha error';
            else
                payload.error.description = 'Server error';
        }

        analytics.sendData(payload);
    }
}

register.registerClass('.widget-form-info', FormInfo);