import Component from '../../libs/component';
import { register } from '../../libs/register';



class CtaModal extends Component {

    constructor(container) {
        super('elem-cta-modal');

        this.cta = container;
        this.modalId = container.dataset.target;
        this.ctaId = container.id;

        this._init();
    }

    _init() {

        this.cta.addEventListener('click', (ev) => {
            ev.preventDefault();

            try {
                let modal = document.querySelector(`${this.modalId}`);
                modal.objReference._openModal(this.ctaId);
            } catch(err) {
                console.log('CTA Modal error on',this.modalId);
            }
        });
    }
}

register.registerClass('.elem-cta-modal', CtaModal);